@font-face {
  font-family: 'advisorycloud';
  src: url('../font/advisorycloud.eot?70562835');
  src: url('../font/advisorycloud.eot?70562835#iefix') format('embedded-opentype'),
       url('../font/advisorycloud.woff2?70562835') format('woff2'),
       url('../font/advisorycloud.woff?70562835') format('woff'),
       url('../font/advisorycloud.ttf?70562835') format('truetype'),
       url('../font/advisorycloud.svg?70562835#advisorycloud') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'advisorycloud';
    src: url('../font/advisorycloud.svg?70562835#advisorycloud') format('svg');
  }
}
*/
[class^="ac-icon-"]:before, [class*=" ac-icon-"]:before {
  font-family: "advisorycloud";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.ac-icon-add-outlined:before { content: '\e800'; } /* '' */
.ac-icon-add-task-black:before { content: '\e801'; } /* '' */
.ac-icon-advisor-outlined:before { content: '\e802'; } /* '' */
.ac-icon-answer-outlined:before { content: '\e803'; } /* '' */
.ac-icon-answer:before { content: '\e804'; } /* '' */
.ac-icon-archive-outlined:before { content: '\e805'; } /* '' */
.ac-icon-bell:before { content: '\e806'; } /* '' */
.ac-icon-benefits-outlined:before { content: '\e807'; } /* '' */
.ac-icon-boardroom-outlined:before { content: '\e808'; } /* '' */
.ac-icon-breakthrough-outlined:before { content: '\e809'; } /* '' */
.ac-icon-breakthrough:before { content: '\e80a'; } /* '' */
.ac-icon-calendar:before { content: '\e80b'; } /* '' */
.ac-icon-cancel-outlined:before { content: '\e80c'; } /* '' */
.ac-icon-cloud-badge:before { content: '\e80d'; } /* '' */
.ac-icon-cloud-logo-outlined:before { content: '\e80e'; } /* '' */
.ac-icon-comment-outlined:before { content: '\e80f'; } /* '' */
.ac-icon-comment:before { content: '\e810'; } /* '' */
.ac-icon-company-outlined:before { content: '\e811'; } /* '' */
.ac-icon-construction-black:before { content: '\e812'; } /* '' */
.ac-icon-dashboard-outlined:before { content: '\e813'; } /* '' */
.ac-icon-docs-outlined:before { content: '\e814'; } /* '' */
.ac-icon-edit:before { content: '\e815'; } /* '' */
.ac-icon-flag-outlined:before { content: '\e816'; } /* '' */
.ac-icon-help-outlined:before { content: '\e817'; } /* '' */
.ac-icon-hide-outlined:before { content: '\e818'; } /* '' */
.ac-icon-image-outlined:before { content: '\e819'; } /* '' */
.ac-icon-info-outlined:before { content: '\e81a'; } /* '' */
.ac-icon-insight-outlined:before { content: '\e81b'; } /* '' */
.ac-icon-invited-outlined:before { content: '\e81c'; } /* '' */
.ac-icon-key-outlined:before { content: '\e81d'; } /* '' */
.ac-icon-like-outlined:before { content: '\e81e'; } /* '' */
.ac-icon-like-1:before { content: '\e81f'; } /* '' */
.ac-icon-lock-outlined:before { content: '\e820'; } /* '' */
.ac-icon-logout-outlined:before { content: '\e821'; } /* '' */
.ac-icon-mail-outlined:before { content: '\e822'; } /* '' */
.ac-icon-manage-accounts:before { content: '\e823'; } /* '' */
.ac-icon-meeting-outlined:before { content: '\e824'; } /* '' */
.ac-icon-profile-outlined:before { content: '\e825'; } /* '' */
.ac-icon-questions-outlined:before { content: '\e826'; } /* '' */
.ac-icon-questions:before { content: '\e827'; } /* '' */
.ac-icon-refresh-outlined:before { content: '\e828'; } /* '' */
.ac-icon-save-outlined:before { content: '\e829'; } /* '' */
.ac-icon-show-outlined:before { content: '\e82a'; } /* '' */
.ac-icon-settings-outlined:before { content: '\e82b'; } /* '' */
.ac-icon-search-outlined:before { content: '\e82c'; } /* '' */
.ac-icon-star-outlined:before { content: '\e82d'; } /* '' */
.ac-icon-star:before { content: '\e82e'; } /* '' */
.ac-icon-trash-outlined:before { content: '\e82f'; } /* '' */
.ac-icon-updates-outlined:before { content: '\e830'; } /* '' */
.ac-icon-updates:before { content: '\e831'; } /* '' */
.ac-icon-upgrade-outlined:before { content: '\e832'; } /* '' */
.ac-icon-upload-cloud-outlined:before { content: '\e833'; } /* '' */
.ac-icon-upload-outlined:before { content: '\e834'; } /* '' */
.ac-icon-video-outlined:before { content: '\e835'; } /* '' */
.ac-icon-group-add:before { content: '\e836'; } /* '' */
.ac-icon-whatshot:before { content: '\e837'; } /* '' */
.ac-icon-human-board:before { content: '\e838'; } /* '' */
.ac-icon-push-pin:before { content: '\e839'; } /* '' */
.ac-icon-cloud-logo:before { content: '\e83a'; } /* '' */
.ac-icon-verified-outlined:before { content: '\e83b'; } /* '' */
.ac-icon-bullhorn-outline:before { content: '\e83c'; } /* '' */
.ac-icon-bubbles-question:before { content: '\e83d'; } /* '' */
.ac-icon-like:before { content: '\e83e'; } /* '' */
.ac-icon-comments:before { content: '\e83f'; } /* '' */
.ac-icon-calendar-meeting:before { content: '\e840'; } /* '' */
.ac-icon-boardroom_icon:before { content: '\e841'; } /* '' */
.ac-icon-join_page:before { content: '\e842'; } /* '' */
.ac-icon-company_website:before { content: '\e843'; } /* '' */
.ac-icon-airplane:before { content: '\e844'; } /* '' */
.ac-icon-star-border:before { content: '\e845'; } /* '' */
.ac-icon-star-filled:before { content: '\e846'; } /* '' */
.ac-icon-people-team:before { content: '\e847'; } /* '' */
.ac-icon-hamburger:before { content: '\e848'; } /* '' */
.ac-icon-pin:before { content: '\e849'; } /* '' */
